export const ondoLGAs = [
  "Akoko North-East",
  "Akoko North-West",
  "Akoko South-East",
  "Akoko South-West",
  "Akure North",
  "Akure South",
  "Ese Odo",
  "Idanre",
  "Ifedore",
  "Ilaje",
  "Ile Oluji",
  "Okeigbo",
  "Irele",
  "Odigbo",
  "Okitipupa",
  "Ondo East",
  "Ondo West",
  "Ose",
  "Owo",
];
