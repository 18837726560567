import React from "react";
import classNames from "classnames";
import { useHistory, Link } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";

export function HeadingWithNavigation({
  className,
  title,
  section,
  backUrl = null,
  showBackButton = true,
}) {
  const { goBack } = useHistory();
  const backContents = (
    <React.Fragment>
      <span className="w-4 h-4 mr-1">
        <BackIcon />
      </span>
      <span className="text-sm leading-4">{section}</span>
    </React.Fragment>
  );
  const backClasses = "inline-flex items-center text-subtext mb-1 outline-none";
  return (
    <div className={classNames("flex flex-col items-start", className)}>
      {showBackButton &&
        (!!backUrl ? (
          <Link to={backUrl} className={backClasses}>
            {backContents}
          </Link>
        ) : (
          <button type="button" className={backClasses} onClick={goBack}>
            {backContents}
          </button>
        ))}
      <h1 className="text-heading text-xl font-medium">{title}</h1>
    </div>
  );
}
