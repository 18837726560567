import axios from "axios";
import { isLoggedIn, getAuthToken } from "./auth";
import { isNotNullOrUndefined } from "./functions";
import moment from "moment";

export const BASE_URL = process.env.REACT_APP_API_URL;

export function setupAxios() {
  axios.defaults.baseURL = BASE_URL;
  if (isLoggedIn()) {
    axios.defaults.headers.common.Authorization = `Bearer ${getAuthToken()}`;
  } else {
    axios.defaults.headers.common.Authorization = undefined;
  }
}

export function jsonToURLParams(object) {
  const present = Object.entries(object)
    .filter(([_, v]) => isNotNullOrUndefined(v) && !!String(v))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const query = new URLSearchParams(present);

  return query.toString();
}

export function formatDateForAPI(date) {
  return moment(date).format("YYYY-MM-DD");
}
