import React from "react";
import classNames from "classnames";
import { ReactComponent as DocumentIcon } from "../../assets/icons/document.svg";
import { formatNumber } from "../../utils/functions";
import { formatETL } from "./formatETL";

export function ValidationProgress({
  fileName,
  numRows,
  secondsLeft,
  percentageComplete,
  status,
  className,
  ...props
}) {
  const failed = status === "failed";
  const success = status === "success";
  const complete = failed || success;

  return (
    <div
      className={classNames(
        "border rounded px-2.5 py-3 flex items-center relative w-full",
        className
      )}
      {...props}
      role="progressbar"
      aria-valuemin={0}
      aria-valuenow={percentageComplete}
      aria-valuemax={100}
      aria-label={`Validating ${fileName}`}
    >
      <div
        aria-hidden={true}
        className="absolute w-full h-full top-0 left-0 bg-gray-lighter -z-1"
      />
      <div
        aria-hidden={true}
        className={classNames(
          "absolute w-full top-0 bottom-0 left-0 bg-gray-darker border-b-2 transition-transform ease-in-out origin-left -z-1",
          !failed && "border-green-progress",
          failed && "border-orange-progress",
          !complete && "duration-150",
          complete && "duration-300"
        )}
        style={{
          transform: `scaleX(${
            failed
              ? 1
              : secondsLeft === null
              ? 0
              : (percentageComplete ?? 0) / 100
          })`,
        }}
      />
      <DocumentIcon
        aria-hidden={true}
        className="text-gray-icon w-auto h-4.5 mr-2.5"
      />
      <div className="flex flex-col">
        <h5 className="font-medium text-sm leading-3 text-heading mb-2">
          {fileName}
        </h5>
        <p className="text-xs text-gray-description">
          {numRows ? `${formatNumber(numRows)} rows` : "Calculating..."} -{" "}
          {failed
            ? "Validation failed"
            : success
            ? "Validation successful"
            : secondsLeft === 0 || percentageComplete === 100
            ? "Finalizing..."
            : secondsLeft > 0
            ? formatETL(secondsLeft)
            : "Estimating..."}
        </p>
      </div>
    </div>
  );
}
