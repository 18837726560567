import { rest } from "msw";
import { v4 as uuid } from "uuid";
import { BASE_URL } from "../utils/api";

export function isMockAuthenticated() {
  return !!localStorage.getItem("osip-mock-authenticated");
}

const mockLogin = rest.post(`${BASE_URL}/user/login`, function (req, res, ctx) {
  const token = uuid();
  localStorage.setItem("osip-mock-authenticated", token);

  if (
    req.body.email === "osip-sample-user@mailinator.com" &&
    req.body.password === "password"
  ) {
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json({
        success: true,
        message: "Authenticated",
        data: {
          token,
          user: {
            id: uuid(),
            name: "Test User",
          },
        },
      })
    );
  } else {
    return res(
      ctx.delay(1000),
      ctx.status(401),
      ctx.json({
        success: false,
        message: "Invalid login credentials",
        data: {},
      })
    );
  }
});

export const authMocks = [mockLogin];
