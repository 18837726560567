export const toOneDP = (x) => (parseInt(x) === x ? x : x.toFixed(1));

export const pluralize = (x, label) => (x === 0 || x > 1 ? label + "s" : label);

export const DAY = 86400;
export const HOUR = 3600;
export const MINUTE = 60;

export const formatETL = (seconds) => {
  if (seconds === Infinity) {
    return `Unknown time left`;
  }
  else if (seconds >= DAY) {
    const value = Math.ceil(seconds / DAY);
    return `${value} ${pluralize(value, "day")} left`;
  }
  else if (seconds >= HOUR) {
    const value = toOneDP(seconds / HOUR);
    return `${value} ${pluralize(parseFloat(value), "hour")} left`;
  }
  else if (seconds >= MINUTE) {
    const value = Math.ceil(seconds / MINUTE);
    return `${value} ${pluralize(value, "minute")} left`;
  }
  else if (seconds > 1) {
    return `${Math.ceil(seconds)} ${pluralize(seconds, "second")} left`;
  }
  else {
    return `Less than a second left`;
  }
};
