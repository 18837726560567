import React from "react";
import classNames from "classnames";
import "./Spinner.css";

export const SpinnerSize = Object.freeze({
  Small: "w-4 h-4",
  Medium: "w-6 h-6",
  Large: "w-8 h-8",
});

export const SpinnerVariant = Object.freeze({
  Primary: "primary",
  White: "white",
});

export function Spinner({
  className,
  size = SpinnerSize.Small,
  variant = SpinnerVariant.Primary,
  ...props
}) {
  return (
    <div
      className={classNames(
        "spinner border-4 border-transparent rounded-full",
        size,
        variant,
        className
      )}
      aria-label="Loading..."
      {...props}
    />
  );
}
