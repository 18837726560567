import React from "react";
import classNames from "classnames";
import "./Stepper.css";

function Step({ step, status = "incomplete" }) {
  return (
    <li
      className={classNames(
        "stepper-step",
        status === "active" && "stepper-step-active",
        status === "done" && "stepper-step-done"
      )}
    >
      <span className="stepper-step-name">{step.name}</span>
      <span className="sr-only">
        {status === "active" && "(Active)"}
        {status === "done" && "(Complete)"}
        {status === "incomplete" && "(Incomplete)"}
      </span>
    </li>
  );
}

function isStepActive(stepId, activeStepId, steps) {
  return stepId === activeStepId;
}

function isStepDone(stepId, activeStepId, steps) {
  return (
    steps.findIndex((s) => s.id === stepId) <
    steps.findIndex((s) => s.id === activeStepId)
  );
}

function getStepStatus(stepId, activeStepId, steps) {
  switch (true) {
    case isStepActive(stepId, activeStepId, steps):
      return "active";
    case isStepDone(stepId, activeStepId, steps):
      return "done";
    default:
      return "incomplete";
  }
}

export function Stepper({ steps = [], activeStep, className, name, ...props }) {
  return (
    <ol
      aria-label={`Steps for ${name}`}
      className={classNames("stepper list-none flex", className)}
      {...props}
    >
      {steps.map((step) => (
        <Step
          key={step.id}
          step={step}
          status={getStepStatus(step.id, activeStep, steps)}
        />
      ))}
    </ol>
  );
}
