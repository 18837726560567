import React from "react";
import classNames from "classnames";

export function Header({ className, ...props }) {
  return (
    <header
      className={classNames("h-16 bg-heading w-full flex-shrink-0", className)}
      {...props}
    ></header>
  );
}
