import { useSelector } from "react-redux";
import { authSelectors } from "../routes/auth/authSlice";
import { isPermitted } from "../utils/rbac";

export function usePermission() {
  const user = useSelector(authSelectors.user);
  function hasPermission(permissions, data) {
    if (!user) return false;
    return isPermitted(user, permissions, data);
  }
  return {
    hasPermission,
  };
}
