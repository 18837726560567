import React from "react";
import { Header } from "../components/Header/Header";
import { Switch, Redirect } from "react-router-dom";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { appRoutes } from "./routes";
import { CreateTransaction } from "./app/Transaction/Create";
import { TransactionList } from "./app/Transaction/List";
import { ProtectedRoute } from "./router";
import { Permissions } from "../utils/rbac";

const routeMap = Object.freeze({
  [appRoutes.Transaction.Create.Index]: {
    exact: false,
    component: CreateTransaction,
    permissions: Permissions.CreateTransaction,
  },
  [appRoutes.Transaction.List.Index]: {
    exact: false,
    component: TransactionList,
  },
});

const routes = [
  appRoutes.Transaction.Create.Index,
  appRoutes.Transaction.List.Index,
];

const redirects = [
  {
    path: appRoutes.Transaction.Index,
    to: appRoutes.Transaction.List.Index,
  },
  {
    path: appRoutes.Index,
    to: appRoutes.Transaction.List.Index,
  },
];

export function AppLayout() {
  return (
    <React.Fragment>
      <Header />
      <main className="flex flex-row mt-5 flex-1 h-full">
        <Sidebar className="mr-16" />
        <div className="container px-4 mx-auto h-full flex flex-col">
          <Switch>
            {routes.map((route) => {
              const routeDetails = routeMap[route];
              return (
                <ProtectedRoute
                  key={route}
                  path={route}
                  exact={routeDetails.exact}
                  component={routeDetails.component}
                  render={routeDetails.render}
                  permissions={routeDetails.permissions}
                  checkAuthorization={routeDetails.checkAuthorization}
                />
              );
            })}
            {redirects.map((redirect) => {
              return (
                <Redirect
                  key={`${redirect.path}=>${redirect.to}`}
                  {...redirect}
                />
              );
            })}
          </Switch>
        </div>
      </main>
    </React.Fragment>
  );
}
