import { setupWorker } from "msw";
import { authMocks } from "./auth";
import { transactionMocks } from "./transactions";

const combine = (arrs) => arrs.reduce((acc, arr) => acc.concat(arr), []);

const mocks = combine([authMocks, transactionMocks]);

const worker = setupWorker(...mocks);

worker.start();
