import { useReducer, useCallback, useRef, useEffect } from "react";
import { isNotNullOrUndefined } from "../utils/functions";

const initialState = {
  timeLeft: null,
  currentSpeed: null,
};

function progressDurationReducer(state = initialState, action) {
  switch (action.type) {
    case "progress":
      return {
        ...state,
        timeLeft: action.timeLeft,
        currentSpeed: action.speed,
      };
    case "reset": {
      return { ...initialState };
    }
    default:
      return state;
  }
}

export function useProgressDuration(currentProgress) {
  const [state, dispatch] = useReducer(progressDurationReducer, initialState);
  const watchRef = useRef({
    totalSize: 0,
    totalProcessed: 0,
    timeLeft: null,
    currentSpeed: null,
    speeds: [],
    lastUpdateTime: 0,
  });

  const progress = useCallback(function progress({ total, done }) {
    const fastState = watchRef.current;
    const currentTime = Date.now();
    const timeSinceLastUpdate = currentTime - (fastState.lastUpdateTime ?? 0);
    const processedSinceLastUpdate = done - fastState.totalProcessed;
    // Speed in x per second
    const speed =
      timeSinceLastUpdate > 0 && processedSinceLastUpdate > 0
        ? processedSinceLastUpdate / (timeSinceLastUpdate / 1000)
        : fastState.currentSpeed;
    fastState.speeds.push(speed);
    if (fastState.speeds.length > 10) fastState.speeds.shift();
    const avgSpeed =
      fastState.speeds.reduce((acc, x) => acc + x, 0) / fastState.speeds.length;
    // timeLeft in seconds
    let timeLeft = (total - done) / avgSpeed;
    watchRef.current = {
      totalSize: total,
      totalProcessed: done,
      timeLeft: timeLeft === Infinity ? fastState.timeLeft : timeLeft,
      currentSpeed: avgSpeed,
      lastUpdateTime: currentTime,
      speeds: fastState.speeds,
    };
    dispatch({
      type: "progress",
      timeLeft,
      speed: avgSpeed,
    });
  }, []);

  const reset = useCallback(function reset() {
    dispatch({
      type: "reset",
    });
  }, []);

  useEffect(() => {
    if (
      isNotNullOrUndefined(currentProgress) &&
      isNotNullOrUndefined(currentProgress.total) &&
      isNotNullOrUndefined(currentProgress.done)
    ) {
      progress(currentProgress);
    }
  }, [currentProgress, progress]);

  const { timeLeft, currentSpeed } = state;

  return {
    timeLeft,
    currentSpeed,
    progress: currentProgress,
    reset,
  };
}
