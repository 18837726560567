import moment from "moment";

export function fullTextSearchObject(
  needle,
  haystack,
  blacklist = ["id", "date"]
) {
  const entries = Object.entries(haystack).filter(
    ([key]) => !blacklist.includes(key)
  );

  return entries.some(([_, value]) =>
    Array.isArray(value)
      ? value
          .map((value) => fullTextSearchObject(needle, value, blacklist))
          .some((x) => x)
      : typeof value === "object"
      ? fullTextSearchObject(needle, value, blacklist)
      : String(value).toLowerCase().includes(String(needle).toLowerCase())
  );
}

export function isNotEmptyString(value) {
  return !!value && !!String(value).trim();
}

const sortFuncs = {
  "date-recent": ({ date: dateA }, { date: dateB }) =>
    moment(dateB) - moment(dateA),
  "date-oldest": ({ date: dateA }, { date: dateB }) =>
    moment(dateA) - moment(dateB),
};

export function applySort(data, sortType) {
  if (!sortFuncs[sortType]) {
    console.warn(`Unknown sort type: ${sortType}`);
    return data;
  }
  return data.sort(sortFuncs[sortType]);
}
