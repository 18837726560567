import React, { useEffect } from "react";
import { ReactComponent as CheckmarkCircleIcon } from "../../../../assets/icons/checkmark-circle.svg";
import { ButtonVariants, Button } from "../../../../components/Form/Button";
import { appRoutes } from "../../../routes";
import { useTransactionContext } from "./TransactionContext";
import { useHistory } from "react-router-dom";

export function Pay() {
  const { isStepDone } = useTransactionContext();
  const { push } = useHistory();

  useEffect(() => {
    if (!isStepDone("review")) push(appRoutes.Transaction.Create.Review);
  }, [push, isStepDone]);
  return (
    <div
      className="flex flex-col items-center max-w-full"
      style={{ width: "26.5rem" }}
    >
      <CheckmarkCircleIcon className="text-green-progress w-32 h-32 mb-5" />
      <h5 className="text-subheading text-xl leading-6 mb-6 font-medium">
        Transaction Initiated
      </h5>
      <p className="text-subheading text-sm leading-5 mb-12 text-center">
        A report will be sent to your email lorem ipsum dolor amet connecteur
        vous et je suis deux mille.
      </p>
      <Button
        to={appRoutes.Transaction.List.Index}
        block
        variant={ButtonVariants.Green}
      >
        Back to Transactions
      </Button>
    </div>
  );
}
