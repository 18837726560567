import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  user: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = {};
    },
  },
});

export const authActions = authSlice.actions;

export const authSelectors = {
  isLoggedIn(state) {
    return state.auth.isLoggedIn;
  },
  user(state) {
    return state.auth.user;
  },
};

export const authReducer = authSlice.reducer;
