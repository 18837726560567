import React from "react";
import { HeadingWithNavigation } from "../../../../components/HeadingWithNavigation/HeadingWithNavigation";
import { Card } from "../../../../components/Card/Card";
import { CardHeader } from "../../../../components/Card/CardHeader";
import { appRoutes } from "../../../routes";
import { Switch, Route, Redirect } from "react-router-dom";
import { Stepper } from "../../../../components/Stepper/Stepper";
import { CardBody } from "../../../../components/Card/CardBody";
import { useTransactionContext } from "./TransactionContext";
import { steps } from "./steps";

export function CreateTransaction() {
  const { reset, getActiveStep } = useTransactionContext();
  return (
    <React.Fragment>
      <HeadingWithNavigation
        className="mb-12"
        title="Create Transaction"
        section="Transaction List"
      />
      <Card className="flex-1 mb-24 h-page-content min-h-page-content flex flex-col">
        <CardHeader className="flex justify-center items-center">
          <Stepper
            steps={steps}
            activeStep={getActiveStep()}
            name="Create Transaction"
          />
        </CardHeader>
        <CardBody className="flex-1 flex justify-center">
          <Switch>
            {steps.map((step) => (
              <Route key={step.id} path={step.url} component={step.component} />
            ))}
            <Route
              path={appRoutes.Transaction.Create.Index}
              exact
              render={() => {
                reset();
                return <Redirect to={appRoutes.Transaction.Create.Recipient} />;
              }}
            />
          </Switch>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
