import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
// import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg";
import { ReactComponent as TransactionsIcon } from "../../assets/icons/transactions.svg";
// import { ReactComponent as BeneficiariesIcon } from "../../assets/icons/beneficiaries.svg";
import { appRoutes } from "../../routes/routes";

const sidebarItems = [
  // {
  //   title: "Overview",
  //   icon: <HomeIcon />,
  //   url: appRoutes.Overview.Index,
  // },
  {
    title: "Transaction",
    icon: <TransactionsIcon />,
    url: appRoutes.Transaction.Index,
  },
  // {
  //   title: "Beneficiaries",
  //   icon: <BeneficiariesIcon />,
  //   url: appRoutes.Beneficiaries.Index,
  // },
];

function SidebarItem({ title, icon, url }) {
  return (
    <NavLink
      to={url}
      className="text-sm leading-4 py-2 px-2.5 flex items-center hover:text-heading transition duration-300 ease-out w-full mb-3 outline-none"
      activeClassName="text-heading bg-white rounded shadow-raised"
    >
      <span className="w-5 h-5 mr-4" aria-hidden={true}>
        {icon}
      </span>
      <span>{title}</span>
    </NavLink>
  );
}

export function Sidebar({ className, ...props }) {
  return (
    <aside
      className={classNames(
        "flex flex-col text-subtext w-sidebar pl-2.5",
        className
      )}
      {...props}
    >
      {sidebarItems.map(({ title, icon, url }) => (
        <SidebarItem key={url} title={title} url={url} icon={icon} />
      ))}
    </aside>
  );
}
