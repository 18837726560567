import React from "react";
import classNames from "classnames";
import "./Table.css";
import { Spinner, SpinnerSize } from "../Spinner/Spinner";

export function Table({
  className,
  loading = false,
  empty = false,
  EmptyState,
  tableClassName,
  children,
  ...props
}) {
  return (
    <div
      className={classNames(
        "overflow-x-auto flex-1 h-full relative",
        className
      )}
    >
      {loading && (
        <div
          aria-hidden={true}
          className="absolute z-10 inset-0 bg-overlay-light flex items-center justify-center"
        >
          <Spinner size={SpinnerSize.Large} />
        </div>
      )}
      {empty && (
        <div className="absolute z-10 inset-0 bg-transparent flex items-center justify-center">
          {!!EmptyState ? (
            <EmptyState />
          ) : (
            <p className="text-subtext">No records to display...</p>
          )}
        </div>
      )}
      <div
        className={classNames("w-full h-full flex flex-col", tableClassName)}
        aria-label={loading ? "Loading..." : undefined}
        role="table"
        {...props}
      >
        {children}
      </div>
    </div>
  );
}

export function TableHead({ children, className, ...props }) {
  return (
    <div
      className={classNames("border border-light px-6 py-3.5 flex-shrink-0")}
      style={{ backgroundColor: "#f7f7f7" }}
      role="rowgroup"
      {...props}
    >
      {children}
    </div>
  );
}

export function TableBody({ children, className, ...props }) {
  return (
    <div
      role="rowgroup"
      className={classNames(
        "flex-1 flex-shrink-0 overflow-y-auto h-full pt-3",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export function HeaderRow({ children, className, ...props }) {
  return (
    <div
      className={classNames("flex items-center", className)}
      role="row"
      {...props}
    >
      {children}
    </div>
  );
}

export const Row = React.forwardRef(function Row(
  { children, className, onClick, ...props },
  ref
) {
  function handleKeyUp(event) {
    event.persist();
    if (event.code === "Enter" || event.keyCode === 13) {
      onClick(event);
    } else {
      props.onKeyUp && props.onKeyUp(event);
    }
  }

  return (
    <div
      ref={ref}
      role="row"
      className={classNames(
        "px-6 py-4 border-b flex items-start",
        !!onClick &&
          "hover:bg-gray-background focus:bg-gray-background cursor-pointer transition duration-300 outline-none",
        className
      )}
      onClick={onClick}
      tabIndex={!!onClick ? 0 : undefined}
      onKeyUp={!!onClick ? handleKeyUp : undefined}
      {...props}
    >
      {children}
    </div>
  );
});

export function Cell({
  className,
  children,
  header,
  rightAligned,
  small,
  ...props
}) {
  return (
    <span
      className={classNames(
        "custom-cell flex-grow flex-shrink",
        header && "text-xs text-subtext leading-3 font-bold",
        !header && "text-sm text-subheading leading-5",
        rightAligned && "text-right",
        !small && "min-w-32 max-w-32",
        small && "min-w-12 max-w-12",
        className
      )}
      {...props}
    >
      {children}
    </span>
  );
}
