import { createRouteObject } from "../utils/functions";

export const appRoutes = createRouteObject("/app", (appRoot) => ({
  Transaction: createRouteObject(
    `${appRoot}/transaction`,
    (transactionRoot) => ({
      Create: createRouteObject(`${transactionRoot}/create`, (createRoot) => ({
        Recipient: `${createRoot}/recipient`,
        Amount: `${createRoot}/amount`,
        Review: `${createRoot}/review`,
        Pay: `${createRoot}/pay`,
      })),
      List: createRouteObject(`${transactionRoot}/list`),
    })
  ),
  Overview: createRouteObject(`${appRoot}/overview`),
  Beneficiaries: createRouteObject(`${appRoot}/beneficiaries`),
  Error404: createRouteObject(`${appRoot}/404`),
}));

export const routes = Object.freeze({
  Auth: createRouteObject(undefined, {
    Login: "/auth/login",
  }),
  App: createRouteObject("/app", appRoutes),
});
