import { useState, useRef, useCallback, useEffect } from "react";

export const WORKER_SERVICE_STATUS = Object.freeze({
  READY: "ready",
  PROCESSING: "processing",
  COMPLETE: "success",
  ERROR: "error",
});

export function useWorkerService(processor) {
  const [status, _setWorkerStatus] = useState(WORKER_SERVICE_STATUS.READY);
  const [currentTask, _setCurrentTask] = useState({});
  const isProcessing = useRef(false);

  const setWorkerStatus = useCallback((status) => {
    isProcessing.current = status === WORKER_SERVICE_STATUS.PROCESSING;
    _setWorkerStatus(status);
  }, []);

  const setCurrentTask = useCallback((currentTask) => {
    _setCurrentTask((prevState) => ({
      ...prevState,
      ...(typeof currentTask === "function"
        ? currentTask(prevState)
        : currentTask),
    }));
  }, []);

  const callWorker = useCallback(
    (data) => {
      processor.postMessage(data);
    },
    [processor]
  );

  const runTask = useCallback(
    ({ id, data }) => {
      if (isProcessing.current) {
        throw new Error("Cannot run another task while one is running");
      }

      callWorker({ id, data });
    },
    [callWorker]
  );

  const handleMessage = useCallback(
    (e) => {
      const [type, data] = e.data;

      switch (type) {
        case "task-start":
          const { id } = data;
          setWorkerStatus(WORKER_SERVICE_STATUS.PROCESSING);
          setCurrentTask({
            id,
            complete: false,
            result: null,
            error: null,
            progress: { total: null, done: null },
          });
          break;
        case "task-progress":
          const { total, done } = data;
          setCurrentTask({
            progress: { total, done },
          });
          break;
        case "task-complete":
          const { result } = data;
          setWorkerStatus(WORKER_SERVICE_STATUS.COMPLETE);
          setCurrentTask((task) => ({
            complete: true,
            progress: {
              ...task.progress,
              done: task.progress.total,
            },
            result,
          }));
          break;
        case "task-error":
          const { error } = data;
          if (process.env.NODE_ENV !== "production")
            console.warn(`[Worker Service:Task:${id}]`, error);
          setCurrentTask({
            error,
          });
          setWorkerStatus(WORKER_SERVICE_STATUS.ERROR);
          break;
        default:
        // noop
      }
    },
    [setCurrentTask, setWorkerStatus]
  );

  const handleError = useCallback(
    (error) => {
      if (process.env.NODE_ENV !== "production")
        console.warn(`[Worker Service]`, error);
      setWorkerStatus(WORKER_SERVICE_STATUS.ERROR);
      setCurrentTask({
        error,
      });
    },
    [setCurrentTask, setWorkerStatus]
  );

  useEffect(() => {
    processor.addEventListener("message", handleMessage);
    processor.addEventListener("error", handleError);
    return () => {
      processor.removeEventListener("message", handleMessage);
      processor.removeEventListener("error", handleError);
    };
  }, [processor, handleMessage, handleError]);

  return {
    status,
    currentTask,
    runTask,
  };
}
