/**
 * deepFreeze recusively freezes an object and it's fields,
 * essentially making it immutable.
 * @param {Object} object The object to be deep-frozen
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
 */
export function deepFreeze(object) {
  // Retrieve the property names defined on object
  var propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self

  for (let name of propNames) {
    let value = object[name];

    if (value && typeof value === "object") {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

/**
 * Creates a route object that contains the index route and additional
 * child routes
 * @param {string} indexRoute The index/home route
 * @param {(Object|Function)} [routeMap={}] The additional child routes or a function that returns the additional child routes. The function gets called with the indexRoute as an argument
 */
export function createRouteObject(indexRoute, routeMap = {}) {
  const route = {
    Index: indexRoute,
    ...(typeof routeMap === "function" ? routeMap(indexRoute) : routeMap),
  };
  return Object.freeze(route);
}

/**
 * Convers the given number (in megabytes) to bytes
 * @param {number} megabytes The number to convert, in MB
 * @returns The number in bytes
 */
export function toBytes(megabytes) {
  return megabytes * 1024 * 1024;
}

/**
 * Converts a given file size in bytes to a human readable form
 * @example toHumanReadableFileSize(1024) // 1MiB
 * @example toHumanReadableFileSize(1000, false) // 1MB
 * @param {number} bytes The file size in bytes
 * @param {boolean} si Use SI (1000) or binary (1024)
 * @param {number} dp Number of decimal places
 * @see https://stackoverflow.com/a/14919494/8359475
 */
export function toHumanReadableFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

/**
 * formatCurrency takes an amount and returns a string that contains
 * the provided amount in currency form
 * @param {number} amount The amount to be formatted as a string
 * @param {bool} withSymbol Keep the currency symbol as part of the returned string
 * @param {bool} stripDecimals Remove decimals after the conversion
 */
export function formatCurrency(
  amount,
  withSymbol = false,
  stripDecimals = false
) {
  let formatted = new Intl.NumberFormat("en-NG", {
    currency: "NGN",
    style: "currency",
  }).format(amount);
  if (!withSymbol) formatted = formatted.substr(withSymbol ? 0 : 1);
  if (stripDecimals) formatted = formatted.substring(0, formatted.indexOf("."));
  return formatted;
}

/**
 * Checks if the provided value is neither null, nor undefined
 * @param {*} value The value to check
 * @returns {boolean}
 */
export function isNotNullOrUndefined(value) {
  return value !== null && value !== undefined;
}

/**
 * Checks if the provided value is either null or undefined
 * @param {*} value The value to check
 * @returns {boolean}
 */
export function isNullOrUndefined(value) {
  return !isNotNullOrUndefined(value);
}

/**
 * Formats the given number in human readable format
 * @param {(number|string)} value The number to be formatted (gets converted to a float)
 * @example
 * formatNumber(1000); // 1,000
 * formatNumber('1000.33'); // 1,000.33
 */
export function formatNumber(value) {
  return parseFloat(value).toLocaleString();
}

/**
 * Checks if the given object doesn't have any fields in it
 * @param {Object} object The object to check
 * @see https://stackoverflow.com/a/32108184
 */
export function isEmptyObject(object) {
  // because Object.keys(new Date()).length === 0;
  // we have to do some additional check
  return Object.keys(object).length === 0 && object.constructor === Object;
}
