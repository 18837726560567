import React from "react";
import classNames from "classnames";
import "./Input.css";
import { useField } from "formik";
import Cleave from "cleave.js/react";
import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import Select, { components } from "react-select";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar.svg";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

export function Input({
  className,
  classes = { input: "", label: "" },
  type = "text",
  label,
  value,
  onChange,
  placeholder = "",
  valid = true,
  message = null,
  prefix = null,
  formatOptions = null,
  disabled = null,
  flat = false,
  small = false,
  icon: Icon = null,
  ...props
}) {
  return (
    <div
      className={classNames(
        "flex input-group relative",
        !prefix && "flex-col",
        !!prefix && "flex-row items-center input-group-prefixed border-2",
        !flat && !!prefix && "rounded",
        !!prefix && !valid && "border-orange-progress",
        disabled && "cursor-not-allowed",
        className
      )}
    >
      {!!prefix && (
        <div className="input-group-prefix p-4 text-xl text-subheading leading-5">
          {prefix}
        </div>
      )}
      <div
        className={classNames(
          "flex flex-col flex-1",
          !!prefix && "ml-2.5",
          valid && !!prefix && "mr-2.5"
        )}
      >
        <label
          className={classNames(
            "input-group-label",
            disabled && "cursor-not-allowed",
            classes.label
          )}
          htmlFor={`input-${label}`}
        >
          {label}
        </label>
        <div className="relative">
          {React.createElement(!!formatOptions ? Cleave : "input", {
            type: type,
            className: classNames(
              "input-group-input w-full",
              !valid && "border-orange-progress",
              disabled && "cursor-not-allowed",
              !flat && "rounded",
              !small && !prefix && "text-sm leading-5",
              small && "text-xs leading-4",
              !small && !!prefix && "text-heading text-base",
              !!Icon && "pl-2 pr-10",
              !Icon && "pl-2 pr-2",
              classes.input
            ),
            value,
            onChange,
            placeholder,
            id: `input-${label}`,
            options: formatOptions ?? undefined,
            disabled,
            ...props,
          })}
          {Icon && (
            <span className="absolute right-4 top-2.5">
              <Icon className="w-4 h-4 text-gray-icon" />
            </span>
          )}
        </div>
        {!valid && !!message && !prefix && (
          <p className="text-sm text-orange-progress">{message}</p>
        )}
      </div>
      {!valid && !!message && prefix && (
        <WarningIcon
          className="text-orange-progress ml-auto mr-2.5 w-6 h-6"
          title={message}
        />
      )}
    </div>
  );
}

export function ValidatedInput({
  className,
  type = "text",
  label,
  placeholder = "",
  prefix = null,
  ...props
}) {
  const [field, meta] = useField(props);
  const valid = !(meta.touched && meta.error);
  const message = meta.error;

  const { onChange, ...fieldProps } = field;
  const handleChange = (event) => {
    event.target.value = event.target.rawValue || event.target.value;
    onChange(event);
  };

  return (
    <Input
      type={type}
      className={className}
      label={label}
      placeholder={placeholder}
      valid={valid}
      message={message}
      prefix={prefix}
      onChange={handleChange}
      {...fieldProps}
      {...props}
    />
  );
}

export function Checkbox({
  className,
  label,
  checked,
  onChange,
  valid = true,
  ...props
}) {
  return (
    <label
      className={classNames("checkbox-label", className)}
      htmlFor={`input-${label}`}
    >
      <input
        type="checkbox"
        className={classNames(
          "appearance-none border border-2 w-4 h-4 mr-2.5 rounded p-1",
          checked && "border-white bg-orange"
        )}
        checked={checked}
        onChange={onChange}
        id={`input-${label}`}
        {...props}
      />
      {label}
    </label>
  );
}

export function ValidatedCheckbox({ className, label, ...props }) {
  const [field, meta] = useField({
    ...props,
    type: "checkbox",
  });
  const valid = !(meta.touched && meta.error);

  return (
    <Checkbox
      className={className}
      label={label}
      valid={valid}
      {...field}
      {...props}
    />
  );
}

function createPrefixedValueContainer({ label }) {
  return function ValueContainer(props) {
    return (
      <div className="flex items-stretch flex-grow">
        <label
          className={classNames(
            "text-xs text-lightsubtext mr-2 leading-4 font-body"
          )}
          htmlFor={`input-${label}`}
        >
          {label}
        </label>
        <components.ValueContainer {...props} />
      </div>
    );
  };
}

export function FilterSelect({
  className,
  label,
  defaultValue,
  value,
  options,
  disabled = null,
  onChange,
  prefixedLabel = false,
  ...props
}) {
  const ValueContainer = createPrefixedValueContainer({ label });
  return (
    <div
      className={classNames(
        "flex flex-col input-group",
        disabled && "cursor-not-allowed",
        className
      )}
    >
      {!prefixedLabel && (
        <label
          className={classNames(
            "input-group-label",
            disabled && "cursor-not-allowed"
          )}
          htmlFor={`input-${label}`}
        >
          {label}
        </label>
      )}
      <Select
        className="filter-select"
        classNamePrefix="filter-select"
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        isDisabled={disabled}
        options={options}
        components={{
          DownChevron: ChevronDown, // heh
          ...(prefixedLabel && { ValueContainer }),
        }}
        id={`input-${label}`}
        {...props}
      />
    </div>
  );
}

export function FilterDate({
  label,
  value,
  onChange,
  disabled,
  className,
  ...props
}) {
  return (
    <div
      className={classNames(
        "flex flex-col input-group filter-date relative",
        disabled && "cursor-not-allowed",
        className
      )}
    >
      <label
        className={classNames(
          "input-group-label",
          disabled && "cursor-not-allowed"
        )}
        htmlFor={`input-${label}`}
      >
        {label}
      </label>
      <div className="filter-date-wrapper flex justify-between">
        <DayPickerInput
          id={`input-${label}`}
          classNames={{
            container: "filter-date-container flex-1 flex",
            overlayWrapper:
              "bg-white absolute top-full left-0 shadow-raised z-50 mt-2.5",
          }}
          value={value}
          onDayChange={onChange}
          disabled={disabled}
          {...props}
        />
        <CalendarIcon className="text-gray-icon flex-shrink-0 h-4" />
      </div>
    </div>
  );
}
