import React from "react";
import { UnprotectedRoutes, ProtectedRoutes } from "./routes/router";

function App() {
  return (
    <React.Fragment>
      <UnprotectedRoutes />
      <ProtectedRoutes />
    </React.Fragment>
  );
}

export default App;
