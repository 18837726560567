import React, { useEffect } from "react";
import { Formik } from "formik";
import { ValidatedInput, Input } from "../../../../components/Form/Input";
import { Button, ButtonVariants } from "../../../../components/Form/Button";
import * as Yup from "yup";
import { useTransactionContext } from "./TransactionContext";
import { useHistory } from "react-router-dom";
import { appRoutes } from "../../../routes";

const amountSchema = Yup.object({
  amount: Yup.number()
    .min(1, "Amount cannot be zero")
    .required("Please provide an amount"),
});

export function Amount() {
  const {
    amount,
    recipients,
    isStepDone,
    setAmount,
    nextStep,
    jumpToStep,
  } = useTransactionContext();
  const numBeneficiaries = recipients?.length ?? 0;
  const { push } = useHistory();

  useEffect(() => {
    if (isStepDone("amount")) {
      push(appRoutes.Transaction.Create.Review);
    }
    if (!isStepDone("recipient")) {
      push(appRoutes.Transaction.Create.Recipient);
    }
  }, [isStepDone, push]);

  function handleSubmit({ amount }) {
    setAmount(parseFloat(amount));
    nextStep(() => push(appRoutes.Transaction.Create.Review));
  }

  function goBack() {
    const proceed = window.confirm(
      "Are you sure you want to go back? You will have to re-select the file."
    );
    if (!proceed) return;
    jumpToStep("recipient", () => push(appRoutes.Transaction.Create.Recipient));
  }

  return (
    <Formik
      initialValues={{ amount: amount ?? 0 }}
      validationSchema={amountSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }) => (
        <form
          className="flex flex-col max-w-full"
          style={{ width: "26.5rem" }}
          onSubmit={handleSubmit}
        >
          <ValidatedInput
            name="amount"
            label="Recipient Gets"
            type="text"
            prefix="₦"
            formatOptions={{ numeral: true }}
          />
          <div className="ml-4 border-l py-6">
            <div className="-ml-2.5 flex items-center">
              <span
                aria-label="To be sent to"
                className="bg-gray-border rounded-full w-5 h-5 inline-flex justify-center items-center uppercase text-subtext text-xs"
              >
                x
              </span>
              <strong className="font-medium text-heading text-xs mx-2.5">
                {numBeneficiaries}
              </strong>
              <span className="text-xs leading-3 text-subtext mr-2.5">
                Beneficiaries
              </span>
            </div>
          </div>
          <Input
            className="mb-8"
            label="Total Amount"
            type="text"
            prefix="₦"
            value={values.amount * numBeneficiaries}
            formatOptions={{ numeral: true }}
            disabled
          />
          <div className="flex">
            <Button onClick={goBack} outline className="mr-5">
              Go Back
            </Button>
            <Button
              type="submit"
              className="w-48"
              variant={ButtonVariants.Green}
            >
              Continue
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
