import { setupAxios } from "./api";

const TOKEN = "token";

export function getAuthToken() {
  return localStorage.getItem(TOKEN);
}

export function setAuthToken(token) {
  if (!token) localStorage.removeItem(TOKEN);
  else localStorage.setItem(TOKEN, token);
  setupAxios();
}

export function isLoggedIn() {
  return !!getAuthToken();
}
