import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import classNames from "classnames";
import { HeadingWithNavigation } from "../../../../components/HeadingWithNavigation/HeadingWithNavigation";
import { Button, ButtonVariants } from "../../../../components/Form/Button";
import { appRoutes } from "../../../routes";
import { Card } from "../../../../components/Card/Card";
import {
  Table,
  TableHead,
  TableBody,
  HeaderRow,
  Cell,
  Row,
} from "../../../../components/Table/Table";
import { useTable } from "react-table";
import {
  formatCurrency,
  isNotNullOrUndefined,
} from "../../../../utils/functions";
import moment from "moment";
import {
  Input,
  FilterSelect,
  FilterDate,
} from "../../../../components/Form/Input";
import "./List.css";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { Link } from "react-router-dom";
import { Pagination } from "../../../../components/Pagination/Pagination";
import Drawer from "rc-drawer";
import { usePaginatedQuery } from "react-query";
import { transactionListApi } from "./transactionListApi";
import notification from "../../../../components/Notification/notification";
import { Spinner, SpinnerSize } from "../../../../components/Spinner/Spinner";
import { ondoLGAs } from "../../../../data/ondoLGAs";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";

const _formatDate = (date) => moment(date).format("MMMM D, YYYY");

const statusText = {
  successful: "Success",
  failed: "Failed",
  pending: "Pending",
};

const getStatusText = (status) => statusText[status];

const columns = [
  {
    Header: "Name",
    accessor: "name",
    Cell({ value, row }) {
      return (
        <div className="inline-flex flex-col">
          <span>{value}</span>
          <span className="text-xs text-subtext">{row.original.reference}</span>
        </div>
      );
    },
  },
  {
    Header: "Location",
    accessor: "location",
  },
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Date",
    accessor: "date",
    Cell({ value }) {
      return _formatDate(value);
    },
  },
  {
    Header: "Initiator",
    accessor: "authorName",
    Cell({ value, row }) {
      return (
        <div className="inline-flex flex-col">
          <span>{value}</span>
          <span className="text-xs text-subtext">
            {row.original.transactionMode}
          </span>
        </div>
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell({ value }) {
      return (
        <div
          className={classNames(
            "px-2.5 py-1 rounded inline-flex justify-center text-center min-w-16 font-medium text-xs leading-none",
            value.toLowerCase() === "successful" &&
              "bg-green-light text-green-dark",
            value.toLowerCase() === "failed" && "bg-red-light text-red-dark",
            value.toLowerCase() === "pending" &&
              "bg-gray-detail-label text-gray-detail-text"
          )}
        >
          {getStatusText(value)}
        </div>
      );
    },
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell({ value }) {
      return formatCurrency(value, true);
    },
    rightAligned: true,
  },
];

// eslint-disable-next-line
const TransactionStatus = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "failed",
    label: "Failed",
  },
  {
    value: "success",
    label: "Successful",
  },
];

const SortOptions = [
  {
    value: "date-recent",
    label: "Date (Recent First)",
  },
  {
    value: "date-oldest",
    label: "Date (Oldest First)",
  },
];

const LocationOptions = ondoLGAs.map((x) => ({ value: x, label: x }));

const CategoryOptions = [
  {
    value: "Okada",
    label: "Okada",
  },
];

function DetailItem({ label, text }) {
  return (
    <div className="mb-4.5">
      <p className="text-xs leading-4 text-gray-detail-label mb-2 font-bold">
        {label}
      </p>
      <p className="text-sm leading-5 text-gray-detail-text">{text}</p>
    </div>
  );
}

function DetailRow({ children }) {
  return <div className="grid grid-cols-2">{children}</div>;
}

function TransactionDetails({ open, onClose, transaction }) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      width={"41.8125rem"}
      placement="right"
      handler={false}
      level={null}
    >
      <div className="px-5 py-6 flex items-center justify-between border-b">
        <h5 className="text-detail-text text-xl leading-7 font-normal font-body">
          Transaction Details
        </h5>
        <button onClick={onClose} aria-label="Close drawer">
          <CloseIcon aria-hidden={true} className="w-8 h-8 text-detail-label" />
        </button>
      </div>
      <div className="px-5 py-6">
        <DetailRow>
          <DetailItem label="Reference ID" text={transaction?.reference} />
          <DetailItem label="ORIN" text={transaction?.orin} />
        </DetailRow>
        <DetailRow>
          <DetailItem label="Beneficiary Name" text={transaction?.name} />
          <DetailItem label="Category" text={transaction?.category} />
        </DetailRow>
        <DetailRow>
          <DetailItem label="Location (LGA)" text={transaction?.location} />
          <DetailItem
            label="Transaction Mode"
            text={transaction?.transactionMode}
          />
        </DetailRow>
        <DetailRow>
          <DetailItem
            label="Transaction Date"
            text={_formatDate(transaction?.date)}
          />
          <DetailItem label="Initiator" text={transaction?.authorName} />
        </DetailRow>
        <DetailRow>
          <DetailItem
            label="Amount"
            text={formatCurrency(transaction?.amount, true, false)}
          />
        </DetailRow>
      </div>
    </Drawer>
  );
}

const initialFilterState = {
  search: "",
  date: null,
  location: null,
  category: null,
  sort: null,
};

function EmptyState() {
  return (
    <div className="my-auto flex flex-col flex-1 items-center justify-center text-center text-subheading">
      <img
        className="mb-6"
        src={require("../../../../assets/images/empty.svg")}
        alt="No transactions"
      />
      <h5 className="text-xl mb-3.5">You have no transaction history</h5>
      <p className="text-sm w-empty-state max-w-full">
        You have no transaction in your record.{" "}
        <Link
          className="text-orange hover:text-orange"
          to={appRoutes.Transaction.Create.Index}
        >
          Click here
        </Link>{" "}
        to start
      </p>
    </div>
  );
}

export function TransactionList() {
  const [showDetails, setShowDetails] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [filters, _setFilters] = useState(initialFilterState);
  const [appliedFilters, setAppliedFilters] = useState(initialFilterState);
  const [pagination, _setPagination] = useState({
    page: 0,
    pageCount: 0,
    pageSize: 10,
  });
  const { status, resolvedData, error, isFetching } = usePaginatedQuery(
    [
      "transactionList",
      {
        filters: appliedFilters,
        page: pagination.page,
        pageSize: pagination.pageSize,
      },
    ],
    async (_, params) => {
      const {
        success,
        message,
        data,
        meta,
      } = await transactionListApi.fetchTransactionHistory(params);

      if (!success) {
        throw new Error(message);
      }

      setPagination({
        pageCount: meta.pagination.total_pages,
      });

      return data;
    }
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: resolvedData ?? [],
  });

  const setFilters = useCallback(
    (value) =>
      _setFilters((prevValue) => ({
        ...prevValue,
        ...(typeof value === "function" ? value(prevValue) : value),
      })),
    []
  );

  const setPagination = useCallback(
    (value) =>
      _setPagination((prevValue) => ({
        ...prevValue,
        ...(typeof value === "function" ? value(prevValue) : value),
      })),
    []
  );

  const focusedRow = useRef(null);

  const showTransactionDetails = (transaction) => () => {
    setActiveTransaction(transaction);
    setShowDetails(true);
  };

  const hideDetailsDrawer = () => {
    setShowDetails(false);
    setActiveTransaction(null);
    if (focusedRow.current) focusedRow.current.focus();
  };

  const updateDate = (date) => setFilters({ date });
  const updateLocation = ({ value: location }) => setFilters({ location });
  const updateCategory = ({ value: category }) => setFilters({ category });
  const updateSearch = (search) => setFilters({ search });
  const updateSort = ({ value: sort }) => setFilters({ sort });

  const applyFilters = () => {
    setPagination((pagination) => ({
      ...pagination,
      page: 0,
    }));
    setAppliedFilters(filters);
  };
  const clearFilters = () => {
    setPagination((pagination) => ({
      ...pagination,
      page: 0,
    }));
    setAppliedFilters(initialFilterState);
    setFilters(initialFilterState);
  };
  const hasAppliedFilter = Object.entries(appliedFilters).some(
    ([_, filter]) => {
      let applied = isNotNullOrUndefined(filter);
      if (typeof filter === "string") applied = applied && !!filter;
      return applied;
    }
  );

  const selectOption = (options, value) =>
    options.find((x) => x.value === value) ?? null;

  const canPreviousPage = pagination.page > 0;
  const canNextPage = pagination.page < pagination.pageCount - 1;
  const pageOptions = useMemo(
    () =>
      new Array(pagination.pageCount).fill(undefined).map((_, index) => index),
    [pagination.pageCount]
  );
  const nextPage = () => setPagination(({ page }) => ({ page: page + 1 }));
  const previousPage = () => setPagination(({ page }) => ({ page: page - 1 }));
  const updatePageSize = ({ value: pageSize }) =>
    setPagination({ page: 0, pageCount: 0, pageSize });
  const gotoPage = (page) => setPagination({ page });

  useEffect(() => {
    if (error) {
      notification.failure({
        title: "Failed to fetch transaction history",
        description: error.message,
      });
    }
  }, [error]);

  return (
    <React.Fragment>
      <div className="flex justify-between items-start mb-8">
        <HeadingWithNavigation title="Transactions" showBackButton={false} />
        <Button to={appRoutes.Transaction.Create.Index}>New Transaction</Button>
      </div>
      <div className="mb-12 flex flex-col">
        <div className="flex items-end mb-4">
          <Input
            className="search-filter"
            small
            label="Search"
            placeholder="Search..."
            flat
            icon={SearchIcon}
            value={filters.search}
            onChange={(e) => updateSearch(e.target.value)}
            onKeyUp={(e) => {
              if (e.code === "Enter" || e.keyCode === 13) {
                applyFilters();
              }
            }}
          />
          <FilterDate label="Date" value={filters.date} onChange={updateDate} />
          <FilterSelect
            label="Location"
            options={LocationOptions}
            value={selectOption(LocationOptions, filters.location)}
            onChange={updateLocation}
          />
          <FilterSelect
            label="Category"
            options={CategoryOptions}
            value={selectOption(CategoryOptions, filters.category)}
            onChange={updateCategory}
          />
          <FilterSelect
            className="ml-auto"
            label="Sort by"
            prefixedLabel
            options={SortOptions}
            value={selectOption(SortOptions, filters.sort)}
            onChange={updateSort}
          />
        </div>
        <div className="flex justify-end items-center">
          <Button variant={ButtonVariants.Green} onClick={applyFilters}>
            Apply
          </Button>
          {hasAppliedFilter && (
            <Button className="ml-4" outline onClick={clearFilters}>
              Clear
            </Button>
          )}
        </div>
      </div>
      <Card className="h-page-content min-h-page-content mb-24 flex flex-col">
        <Table
          loading={status === "loading"}
          empty={
            rows.length === 0 && status !== "loading" && status !== "error"
          }
          EmptyState={EmptyState}
          {...getTableProps()}
        >
          <TableHead className="flex-shrink-0">
            {headerGroups.map((headerGroup) => (
              <HeaderRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Cell
                    {...column.getHeaderProps()}
                    header
                    rightAligned={column.rightAligned}
                  >
                    {column.render("Header")}
                  </Cell>
                ))}
              </HeaderRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Row
                  {...row.getRowProps()}
                  onClick={showTransactionDetails(row.original)}
                  onFocus={(event) => (focusedRow.current = event.target)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Cell
                        {...cell.getCellProps()}
                        rightAligned={cell.column.rightAligned}
                      >
                        {cell.render("Cell")}
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
          </TableBody>
        </Table>
        <div className="mt-auto flex items-center justify-end px-10">
          {isFetching && status !== "loading" && (
            <Spinner className="mr-4" size={SpinnerSize.Medium} />
          )}
          <Pagination
            current={pagination.page}
            disablePrev={!canPreviousPage}
            disableNext={!canNextPage}
            pages={pageOptions}
            onPrev={previousPage}
            onNext={nextPage}
            itemsPerPage={pagination.pageSize}
            onChangeItemsPerPage={updatePageSize}
            onClickPage={gotoPage}
          />
        </div>
      </Card>
      <TransactionDetails
        open={showDetails}
        onClose={hideDetailsDrawer}
        transaction={activeTransaction}
      />
    </React.Fragment>
  );
}
