import React from "react";
import { Card } from "../../components/Card/Card";
import { CardBody } from "../../components/Card/CardBody";
import { ValidatedInput, ValidatedCheckbox } from "../../components/Form/Input";

import "./Login.css";
import { Formik } from "formik";
import { Button } from "../../components/Form/Button";
import { Link, useHistory } from "react-router-dom";
import { object, string, bool } from "yup";
import { authApi } from "./authApi";
import notification from "../../components/Notification/notification";
import { setAuthToken } from "../../utils/auth";
import { useDispatch } from "react-redux";
import { authActions } from "./authSlice";
import { appRoutes } from "../routes";

const loginSchema = object({
  email: string()
    .email("Please provide a valid email")
    .required("E-mail is required"),
  password: string().required("Please provide your password"),
  rememberMe: bool(),
});

function LoginForm() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  async function handleSubmit({ email, password }, { setSubmitting }) {
    const { success, message, token, user } = await authApi.login({
      email,
      password,
    });
    if (!success) {
      notification.failure({
        title: "Failed to login",
        description: message,
      });
    } else {
      notification.success({
        title: "Logged in successfully",
      });
      setAuthToken(token);
      dispatch(authActions.login(user));
      setSubmitting(false);
      push(appRoutes.Index);
    }
  }

  return (
    <Formik
      initialValues={{ email: "", password: "", rememberMe: false }}
      validationSchema={loginSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Card as="form" className="login-card-section" onSubmit={handleSubmit}>
          <CardBody>
            <ValidatedInput
              className="mb-6"
              type="email"
              name="email"
              label="Email Address"
              placeholder="email@example.com"
            />
            <ValidatedInput
              className="mb-6"
              type="password"
              name="password"
              label="Password"
              placeholder="*********"
            />
            <div className="flex justify-between items-center mb-6">
              <ValidatedCheckbox name="rememberMe" label="Remember me" />
              <Link className="text-orange text-xs leading-4 font-bold">
                Forgot password?
              </Link>
            </div>
            <Button
              type="submit"
              block
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Login
            </Button>
          </CardBody>
        </Card>
      )}
    </Formik>
  );
}

export function Login() {
  return (
    <React.Fragment>
      <header className="py-10 px-6">
        <div className="container mx-auto">
          <img
            src={require("../../assets/images/logo.svg")}
            alt="Ondo Social Intervention Program"
          />
        </div>
      </header>
      <div className="container mx-auto flex-1 flex justify-center items-center">
        <div className="flex">
          <div className="mr-32 login-text-section">
            <h1 className="text-heading text-size-heading mb-3 font-bold font-display">
              Welcome Back,
            </h1>
            <p className="text-subtext text-size-subtext font-body">
              Login to your OSIP account
            </p>
          </div>
          <LoginForm />
        </div>
      </div>
    </React.Fragment>
  );
}
