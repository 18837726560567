import React from "react";
import classNames from "classnames";
import Select from "react-select";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";

import "./Pagination.css";

export function Pagination({
  current,
  itemsPerPage = 10,
  itemsPerPageOptions = [10, 20, 30, 40, 50],
  totalPages,
  onPrev,
  onNext,
  onChangeItemsPerPage,
  disableNext,
  disablePrev,
  className,
  pages,
  onClickPage,
  ...props
}) {
  const firstThree = pages.slice(0, Math.min(3, current - 2));
  const lastThree = pages.slice(-3);
  const shouldShowFirstThree = current > firstThree[firstThree.length - 1];
  const shouldShowLastThree = current < lastThree[0];
  const currentRegion = pages
    .slice(Math.max(0, current - 3), Math.min(pages.length, current + 3))
    .filter((x) => {
      let allowed = true;
      if (shouldShowFirstThree) {
        allowed = allowed && firstThree.indexOf(x) < 0;
      }
      if (shouldShowLastThree) {
        allowed = allowed && lastThree.indexOf(x) < 0;
      }
      return allowed;
    });
  const selectOptions = itemsPerPageOptions.map((x) => ({
    value: x,
    label: x,
  }));
  const selectValue = selectOptions.find((opt) => opt.value === itemsPerPage);
  return (
    <div className={classNames("flex py-6 items-center", className)} {...props}>
      <div className="inline-flex items-center mr-8">
        <span className="text-sm text-lightsubtext mr-2.5">
          Items per page:
        </span>
        <Select
          className="filter-select pagination"
          classNamePrefix="filter-select"
          value={selectValue}
          options={selectOptions}
          onChange={onChangeItemsPerPage}
          menuPlacement="top"
          components={{
            DownChevron: ChevronDown,
          }}
        />
      </div>
      <div className="inline-flex items-center">
        <button
          className="focus:outline-none"
          onClick={onPrev}
          disabled={disablePrev}
        >
          <ChevronLeft className="w-2.5 text-gray-icon mr-2" />
        </button>
        {shouldShowFirstThree && (
          <React.Fragment>
            {firstThree.map((page) => (
              <button
                className="text-xs focus:outline-none text-gray-icon leading-4 mr-2"
                onClick={() => onClickPage(page)}
              >
                {page + 1}
              </button>
            ))}
            <span className="mr-2">...</span>
          </React.Fragment>
        )}
        {currentRegion.map((page) => (
          <button
            className={classNames(
              "text-xs focus:outline-none leading-4 mr-2",
              page === current ? "text-orange" : "text-gray-icon"
            )}
            key={page}
            onClick={() => onClickPage(page)}
          >
            {page + 1}
          </button>
        ))}
        {shouldShowLastThree && (
          <React.Fragment>
            <span className="mr-2">...</span>
            {lastThree.map((page) => (
              <button
                className="text-xs focus:outline-none text-gray-icon leading-4 mr-2"
                onClick={() => onClickPage(page)}
              >
                {page + 1}
              </button>
            ))}
          </React.Fragment>
        )}
        <button
          className="focus:outline-none"
          onClick={onNext}
          disabled={disableNext}
        >
          <ChevronRight className="w-2.5 text-gray-icon ml-2" />
        </button>
      </div>
    </div>
  );
}
