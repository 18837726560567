import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import "./Dropzone.css";

import { toBytes } from "../../utils/functions";

const overrideEventDefaults = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

// If a dropzone is present, prevent the browser from opening the file
// in case a user accidentally drops the file outside the zone
const usePreventBrowserActionWhilePresent = () => {
  useEffect(() => {
    window.addEventListener("drop", overrideEventDefaults);
    window.addEventListener("dragover", overrideEventDefaults);
    return () => {
      window.removeEventListener("drop", overrideEventDefaults);
      window.removeEventListener("dragover", overrideEventDefaults);
    };
  });
};

export function Dropzone({
  acceptedFileTypes = [],
  onChooseFile,
  className,
  sizeLimit = null,
  ...props
}) {
  const [hasDraggedItem, setHasDraggedItem] = useState(false);
  const [hasUnsupportedFileType, setHasUnsupportedFileType] = useState(false);
  const [hasUnsupportedFileSize, setHasUnsupportedFileSize] = useState(false);
  const inputRef = useRef(null);
  const getUploadedFile = () => {
    const { current } = inputRef;
    if (!current) {
      return null;
    }
    return current.files
      ? (current.files.length > 0 && current.files[0]) || null
      : null;
  };
  const isAcceptedFileType = (file) => {
    if (!file.type) return false;
    if (acceptedFileTypes.length === 0) {
      return true;
    }
    return acceptedFileTypes.some((acceptedFileType) => {
      // Special cases: audio/*, video/*, image/*
      // See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers
      if (acceptedFileType.endsWith("*")) {
        const acceptedType = acceptedFileType.split("/")[0];
        const fileType = file.type.split("/")[0];
        return acceptedType === fileType;
      }
      return acceptedFileType === file.type;
    });
  };
  const isAcceptedFileSize = (file) =>
    !sizeLimit || file.size <= toBytes(sizeLimit);
  const handleInputChange = () => {
    const file = getUploadedFile();
    if (file && !!onChooseFile) {
      if (isAcceptedFileType(file)) {
        if (!isAcceptedFileSize(file)) {
          return setHasUnsupportedFileSize(true);
        }
        setHasUnsupportedFileType(false);
        setHasUnsupportedFileSize(false);
        onChooseFile(file);
      } else {
        setHasUnsupportedFileType(true);
      }
    }
  };
  const triggerFileSelect = () => {
    const { current } = inputRef;
    if (current) {
      current.click();
    }
  };
  const handleDrop = (event) => {
    overrideEventDefaults(event);
    setHasDraggedItem(false);
    setHasUnsupportedFileType(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      if (isAcceptedFileType(file)) {
        if (!isAcceptedFileSize(file)) {
          return setHasUnsupportedFileSize(true);
        }
        setHasUnsupportedFileType(false);
        setHasUnsupportedFileSize(false);
        onChooseFile(file);
      } else {
        setHasUnsupportedFileType(true);
      }
    }
  };
  const handleDragEnter = (event) => {
    overrideEventDefaults(event);
    setHasDraggedItem(true);
  };
  const handleDragLeave = (event) => {
    overrideEventDefaults(event);
    setHasDraggedItem(false);
  };
  const accept = useMemo(() => {
    if (acceptedFileTypes.length === 0) {
      return "*";
    } else {
      return acceptedFileTypes.join(",");
    }
  }, [acceptedFileTypes]);
  usePreventBrowserActionWhilePresent();
  return (
    <div
      className={classNames(
        "border-dashed rounded border-2 p-12 bg-gray-lighter flex flex-col justify-center items-center w-full h-full m-w-",
        hasDraggedItem && "border-orange",
        className
      )}
      onDrag={overrideEventDefaults}
      onDragStart={overrideEventDefaults}
      onDragEnd={overrideEventDefaults}
      onDragOver={overrideEventDefaults}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <input
        className="hidden"
        type="file"
        aria-hidden={true}
        ref={inputRef}
        onChange={handleInputChange}
        accept={accept}
      />
      <img
        className="dropzone-image mb-3"
        src={require("../../assets/images/file-upload.svg")}
        alt=""
      />
      <div className="text-center text-sm leading-5 ">
        <h5 className="text-subheading">
          {!hasDraggedItem
            ? "Drop your excel sheet here, or"
            : "Drop here to upload"}
        </h5>
        {!hasDraggedItem && (
          <button
            type="button"
            className="text-orange"
            onClick={triggerFileSelect}
          >
            browse
          </button>
        )}
        {hasUnsupportedFileType && (
          <p className="mt-2 text-orange-lighter">
            That file type is not supported.
          </p>
        )}
        {hasUnsupportedFileSize && (
          <p className="mt-2 text-orange-lighter">
            The file size is too large. The limit is {sizeLimit}mb
          </p>
        )}
      </div>
    </div>
  );
}
