import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import { steps } from "./steps";
import { useLocation } from "react-router-dom";

export const TransactionContext = createContext();

export const useTransactionContext = () => useContext(TransactionContext);

const getInitialState = () => ({
  steps: steps.map(({ id }) => ({ id, done: false })),
  currentStep: "recipient",
  recipients: [],
  amount: 0,
  narration: "",
});

function createTransactionReducer(state = getInitialState(), action) {
  const newState = { ...state };
  const reducers = {
    setRecipients(_, action) {
      newState.recipients = action.recipients;
    },
    setAmount(_, action) {
      newState.amount = action.amount;
    },
    setNarration(_, action) {
      newState.narration = action.narration;
    },
    jumpToStep(_, action) {
      const step = newState.steps.find((step) => step.id === action.stepId);
      step.done = false;
      newState.currentStep = action.stepId;
    },
    nextStep(state) {
      const currentStepId = state.currentStep;
      const currentStep = newState.steps.find(
        (step) => step.id === currentStepId
      );
      const currentStepIndex = newState.steps.indexOf(currentStep);
      const nextStepIndex = currentStepIndex + 1;
      const nextStep = newState.steps[nextStepIndex];
      currentStep.done = true;
      if (nextStep) {
        newState.currentStep = nextStep.id;
        nextStep.done = false;
      }
    },
    reset() {
      return { ...getInitialState() };
    },
  };

  const reducer = reducers[action.type];

  if (reducer) {
    const result = reducer(state, action);
    return result ?? newState;
  }

  if (process.env.NODE_ENV !== "production") {
    console.warn(
      `Encountered unknown createTransaction action: ${action.type}`
    );
  }

  return state;
}

export function TransactionContextProvider({ children }) {
  const { pathname } = useLocation();

  const getActiveStep = useCallback(() => {
    return steps.find((step) => pathname.startsWith(step.url))?.id;
  }, [pathname]);

  const [state, dispatch] = useReducer(
    createTransactionReducer,
    getInitialState()
  );

  const jumpToStep = useCallback((stepId, callback) => {
    dispatch({
      type: "jumpToStep",
      stepId,
    });
    if (callback) {
      setTimeout(callback);
    }
  }, []);

  const setRecipients = useCallback((recipients) => {
    dispatch({
      type: "setRecipients",
      recipients,
    });
  }, []);

  const setAmount = useCallback((amount) => {
    dispatch({
      type: "setAmount",
      amount,
    });
  }, []);

  const setNarration = useCallback((narration) => {
    dispatch({
      type: "setNarration",
      narration,
    });
  }, []);

  const isStepDone = useCallback(
    (stepId) => state.steps.find((step) => step.id === stepId)?.done,
    [state.steps]
  );

  const nextStep = useCallback(
    (callback) => {
      const currentStep = state.steps.find(
        (step) => step.id === state.currentStep
      );
      const nextStep = state.steps.indexOf(currentStep) + 1;
      if (nextStep) {
        dispatch({
          type: "nextStep",
        });
        if (callback) {
          setTimeout(callback);
        }
      }
    },
    [state.steps, state.currentStep]
  );

  const reset = useCallback(() => {
    dispatch({
      type: "reset",
    });
  }, [dispatch]);

  const contextValue = useMemo(
    () => ({
      currentStep: state.currentStep,
      recipients: state.recipients,
      amount: state.amount,
      narration: state.narration,
      setRecipients,
      setAmount,
      setNarration,
      jumpToStep,
      isStepDone,
      nextStep,
      getActiveStep,
      reset,
    }),
    [
      state.currentStep,
      state.recipients,
      state.amount,
      state.narration,
      setRecipients,
      setAmount,
      setNarration,
      jumpToStep,
      isStepDone,
      nextStep,
      getActiveStep,
      reset,
    ]
  );

  return (
    <TransactionContext.Provider value={contextValue}>
      {children}
    </TransactionContext.Provider>
  );
}
