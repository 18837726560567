import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Login } from "./auth/Login";
import { AppLayout } from "./AppLayout";
import { routes } from "./routes";
import { authSelectors } from "./auth/authSlice";
import { useSelector } from "react-redux";
import { usePermission } from "../hooks/usePermissions";

const routeMap = Object.freeze({
  [routes.Auth.Login]: {
    exact: false,
    component: Login,
  },
  [routes.App.Index]: {
    exact: false,
    component: AppLayout,
  },
});

const guestRoutes = [routes.Auth.Login];
const protectedRoutes = [routes.App.Index];

function defaultAuthorizationChecker({ permissions, hasPermission }) {
  return hasPermission(permissions);
}

export function ProtectedRoute({
  path,
  component: Component,
  render,
  permissions,
  checkAuthorization = defaultAuthorizationChecker,
  ...props
}) {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const { hasPermission } = usePermission();
  const wrappedRender = (props) => {
    if (!isLoggedIn) return <Redirect to={routes.Auth.Login} />;
    if (permissions && !checkAuthorization({ permissions, hasPermission }))
      return <Redirect to={routes.App.Error404} />;
    return render(props);
  };

  const wrappedComponent = function (props) {
    if (!isLoggedIn) return <Redirect to={routes.Auth.Login} />;
    if (permissions && !checkAuthorization({ permissions, hasPermission }))
      return <Redirect to={routes.App.Error404} />;
    return <Component {...props} />;
  };
  wrappedComponent.displayName = `Protected(${
    Component.displayName ?? Component.name
  }Route)`;

  return (
    <Route
      path={path}
      component={!!Component ? wrappedComponent : undefined}
      render={!!render ? wrappedRender : undefined}
      {...props}
    />
  );
}

ProtectedRoute.defaultProps = {
  checkAuthorization: defaultAuthorizationChecker,
};

export function UnprotectedRoutes() {
  return (
    <Switch>
      {guestRoutes.map((guestRoute) => {
        const routeDetails = routeMap[guestRoute];
        return (
          <Route
            key={guestRoute}
            path={guestRoute}
            exact={routeDetails.exact}
            component={routeDetails.component}
            render={routeDetails.render}
          />
        );
      })}
    </Switch>
  );
}

export function ProtectedRoutes() {
  return (
    <Switch>
      {protectedRoutes.map((protectedRoute) => {
        const routeDetails = routeMap[protectedRoute];
        return (
          <ProtectedRoute
            key={protectedRoute}
            path={protectedRoute}
            exact={routeDetails.exact}
            component={routeDetails.component}
            render={routeDetails.render}
            permissions={routeDetails.permissions}
            checkAuthorization={routeDetails.checkAuthorization}
          />
        );
      })}
    </Switch>
  );
}
