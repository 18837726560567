import { rest } from "msw";
import { v4 as uuid } from "uuid";
import { BASE_URL } from "../utils/api";
import { isMockAuthenticated } from "./auth";
import * as faker from "faker";
import { fullTextSearchObject, isNotEmptyString, applySort } from "./mockUtils";
import { ondoLGAs } from "../data/ondoLGAs";
import moment from "moment";

const createAuthor = () => {
  const fname = faker.name.firstName();
  const lname = faker.name.lastName();
  return {
    author: faker.internet.email(fname, lname, "mailinator.com"),
    authorName: `${fname} ${lname}`,
  };
};

const transactionHistoryData = new Array(1000).fill(undefined).map(() => ({
  id: uuid(),
  orin: uuid().substr(0, 10),
  reference: "TR" + uuid().substr(0, 8),
  createdAt: faker.date.recent(30),
  phoneNumber: faker.phone.phoneNumber(),
  name: `${faker.name.firstName()} ${faker.name.lastName()}`,
  status: faker.random.arrayElement(["failed", "successful", "pending"]),
  amount: faker.finance.amount(10000000),
  category: faker.random.arrayElement(["Okada"]),
  source: faker.random.arrayElement(["Bank", "Wallet"]),
  location: faker.random.arrayElement(ondoLGAs),
  channel: faker.random.arrayElement(["Providus"]),
  narration: faker.lorem.sentence(),
  currency: "NGN",
  ...createAuthor(),
  transactionMode: null,
}));

const mockTransactionHistory = rest.get(`${BASE_URL}/transactions`, function (
  req,
  res,
  ctx
) {
  if (!isMockAuthenticated()) {
    return res(
      ctx.delay(1000),
      ctx.status(401),
      ctx.json({
        success: false,
        message: "Unauthenticated",
        data: {},
      })
    );
  }

  let results = transactionHistoryData;

  const query = req.url?.searchParams;

  const search = query?.get("search");
  const date = query?.get("date");
  const location = query?.get("location");
  const category = query?.get("category");
  const sort = query?.get("sort");
  const page = parseInt(query?.get("pageNo")) || 1;
  const pageSize = parseInt(query?.get("pageSize")) || 10;

  if (isNotEmptyString(sort)) {
    results = applySort(results, sort);
  }

  if (isNotEmptyString(search)) {
    results = results.filter((result) =>
      fullTextSearchObject(String(search).trim(), result)
    );
  }

  if (isNotEmptyString(location)) {
    results = results.filter(
      (result) =>
        result.location.toLowerCase() === String(location).toLowerCase()
    );
  }

  if (isNotEmptyString(category)) {
    results = results.filter(
      (result) =>
        result.category.toLowerCase() === String(category).toLowerCase()
    );
  }

  if (isNotEmptyString(date)) {
    results = results.filter((result) =>
      moment(date).isSame(moment(result.date), "day")
    );
  }

  const offset = Math.max(0, (page - 1) * pageSize);
  const limit = Math.min(results.length, offset + pageSize);
  const pageCount = Math.ceil(results.length / pageSize);

  results = results.slice(offset, limit);

  return res(
    ctx.delay(2000),
    ctx.status(200),
    ctx.json({
      success: true,
      message: "Transaction history fetched successfully",
      data: results,
      meta: {
        pagination: {
          current_page: page,
          next_page: Math.max(pageCount, page + 1),
          per_page: pageSize,
          previous_page: Math.max(1, page - 1),
          total_entries: results.length,
          total_pages: pageCount,
        },
      },
    })
  );
});

export const mockCreateTransaction = rest.post(
  `${BASE_URL}/transactions/bulk-transfer`,
  function (req, res, ctx) {
    if (!isMockAuthenticated()) {
      return res(
        ctx.delay(1000),
        ctx.status(401),
        ctx.json({
          success: false,
          message: "Unauthenticated",
          data: {},
        })
      );
    }

    return res(
      ctx.delay(5000),
      ctx.status(200),
      ctx.json({
        success: true,
        message: "Transaction initated",
        data: {},
      })
    );
  }
);

export const transactionMocks = [mockTransactionHistory, mockCreateTransaction];
