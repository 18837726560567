import React from "react";
import classNames from "classnames";
import { Spinner, SpinnerVariant } from "../Spinner/Spinner";
import { deepFreeze } from "../../utils/functions";
import { Link } from "react-router-dom";

export const ButtonVariants = deepFreeze({
  Green: {
    outline:
      "bg-transparent border border-green text-green hover:bg-green hover:text-white",
    normal: "bg-green text-white hover:text-white",
  },
  Orange: {
    outline:
      "bg-transparent border border-orange text-orange hover:bg-orange hover:text-white",
    normal: "bg-orange text-white hover:text-white",
  },
});

export function Button({
  className,
  type = "button",
  to = null,
  onClick,
  disabled = false,
  loading = false,
  children,
  block = false,
  variant = ButtonVariants.Orange,
  outline = false,
  ...props
}) {
  return React.createElement(
    !to ? "button" : Link,
    {
      className: classNames(
        "text-center py-2.5 px-6 font-bold font-body text-sm leading-5 rounded inline-flex justify-center items-center transition duration-300 ease-out",
        outline ? variant.outline : variant.normal,
        block && "w-full",
        disabled && "opacity-75 cursor-not-allowed",
        className
      ),
      type,
      disabled,
      onClick,
      to,
      ...props,
    },
    loading ? <Spinner variant={SpinnerVariant.White} /> : children
  );
}
