import Axios from "axios";
import { jsonToURLParams, formatDateForAPI } from "../../../../utils/api";

async function fetchTransactionHistory({ filters, page, pageSize }) {
  try {
    let endpointUrl = "/transactions?";
    endpointUrl += jsonToURLParams({
      search: filters.search ?? undefined,
      date: !!filters.date ? formatDateForAPI(filters.date) : undefined,
      location: filters.location ?? undefined,
      category: filters.category ?? undefined,
      sort: filters.sort ?? undefined,
      pageNo: page + 1,
      pageSize,
    });
    const response = await Axios.get(endpointUrl);
    const { success, message, data, meta } = response.data;

    return {
      success,
      message,
      data,
      meta,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  }
}

export const transactionListApi = {
  fetchTransactionHistory,
};
