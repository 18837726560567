import { appRoutes } from "../../../routes";
import { Recipient } from "./Recipient";
import { Amount } from "./Amount";
import { Reivew } from "./Review";
import { Pay } from "./Pay";

export const steps = [
  {
    id: "recipient",
    name: "Recipient",
    url: appRoutes.Transaction.Create.Recipient,
    component: Recipient,
  },
  {
    id: "amount",
    name: "Amount",
    url: appRoutes.Transaction.Create.Amount,
    component: Amount,
  },
  {
    id: "review",
    name: "Review",
    url: appRoutes.Transaction.Create.Review,
    component: Reivew,
  },
  {
    id: "pay",
    name: "Pay",
    url: appRoutes.Transaction.Create.Pay,
    component: Pay,
  },
];
