import React, { useReducer, useEffect, useMemo } from "react";
import classNames from "classnames";
import "./Recipient.css";
import { Dropzone } from "../../../../components/Dropzone/Dropzone";
import { EXCEL_MIME_TYPES } from "../../../../utils/constants";
import { ValidationProgress } from "../../../../components/Progress/ValidationProgress";
import { useProgressDuration } from "../../../../hooks/useProgressDuration";
import { ReactComponent as CheckmarkCircleIcon } from "../../../../assets/icons/checkmark-circle.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/warning.svg";
import { Button, ButtonVariants } from "../../../../components/Form/Button";
// eslint-disable-next-line
import CSVValidatorWorker from "worker-loader!../../../../utils/csvValidatorService.worker.js";
import {
  useWorkerService,
  WORKER_SERVICE_STATUS,
} from "../../../../hooks/useWorkerService";
import { formatNumber } from "../../../../utils/functions";
import { useTransactionContext } from "./TransactionContext";
import { useHistory } from "react-router-dom";
import { appRoutes } from "../../../routes";

const initialState = {
  status: "idle",
  file: {},
  result: {},
};

function recipientReducer(state = initialState, action) {
  switch (action.type) {
    case "choose-file":
      return {
        ...state,
        file: action.file,
      };
    case "begin-validation":
      return {
        ...state,
        status: "validating",
      };
    case "validation-success":
      return {
        ...state,
        status: "success",
        result: action.result,
      };
    case "validation-failed":
      return {
        ...state,
        status: "failed",
        result: action.result,
      };
    case "reset": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
}

function Banner({ className, title, icon, children, ...props }) {
  return (
    <div className={classNames("rounded p-5", className)} {...props}>
      <div className="flex items-center mb-4">
        <span className="w-6 h-6 mr-2 inline-block">{icon}</span>
        <h5 className="font-medium text-subheading text-sm leading-5">
          {title}
        </h5>
      </div>
      {children}
    </div>
  );
}

function BannerItem({ className, title, value, ...props }) {
  return (
    <div className={classNames("flex flex-col", className)} {...props}>
      <h6 className="text-xs leading-4 mb-2 text-lightsubtext">{title}</h6>
      <p className="text-sm text-subheading">{value}</p>
    </div>
  );
}

const worker = new CSVValidatorWorker();

export function Recipient() {
  const [state, dispatch] = useReducer(recipientReducer, initialState);
  const { runTask, currentTask, status: serviceStatus } = useWorkerService(
    worker
  );

  const { status, file, result } = state;
  const { timeLeft, reset: resetDuration } = useProgressDuration(
    currentTask?.progress
  );
  const isValidating =
    status === "validating" || status === "success" || status === "failed";
  const doneValidating = status === "success" || status === "failed";

  const percentageValidated =
    (currentTask.progress?.done / currentTask.progress?.total) * 100;

  const errorRows = useMemo(
    () => Array.from(currentTask.result?.errors?.rows ?? []),
    [currentTask.result]
  );
  const errorText = useMemo(() => {
    if (errorRows.length > 10) {
      return (
        errorRows.slice(0, 10).join(", ") +
        ` and ${errorRows.length - 10} other rows`
      );
    }
    return errorRows.join(", ");
  }, [errorRows]);

  const { isStepDone, nextStep, setRecipients } = useTransactionContext();
  const { push } = useHistory();

  function handleChooseFile(file) {
    dispatch({
      type: "choose-file",
      file,
    });
    dispatch({
      type: "begin-validation",
    });
    resetDuration();
    runTask({ id: `validate-csv-${Date.now()}`, data: file });
  }

  function reset() {
    dispatch({
      type: "reset",
    });
  }

  function continueToNextStep() {
    setRecipients(result.json);
    nextStep(() => push(appRoutes.Transaction.Create.Amount));
  }

  useEffect(() => {
    if (currentTask.complete) {
      if (currentTask.result.valid) {
        dispatch({
          type: "validation-success",
          result: currentTask.result,
        });
      } else {
        dispatch({
          type: "validation-failed",
          result: currentTask.result,
        });
      }
    }
  }, [currentTask.complete, currentTask.result]);

  useEffect(() => {
    if (serviceStatus === WORKER_SERVICE_STATUS.ERROR) {
      dispatch({
        type: "validation-failed",
        result: {
          valid: false,
          errors: {
            summary: "An error occured: " + currentTask.error?.message,
            rows: new Set(),
          },
        },
      });
    }
  }, [serviceStatus, currentTask.error]);

  useEffect(() => {
    if (isStepDone("recipient")) {
      push(appRoutes.Transaction.Create.Amount);
    }
  }, [isStepDone, push]);

  return (
    <div className="flex">
      <div className="pr-12 w-48 border-r">
        <h5 className="text-subheading font-medium text-sm leading-5 mb-5">
          Upload Beneficiaries
        </h5>
        <p className="text-lightsubtext text-sm leading-5">
          Click{" "}
          <button type="button" className="text-orange outline-none">
            here to download
          </button>{" "}
          a sample excel file for log uploads
        </p>
      </div>
      <div className="pl-12 excel-upload">
        {status === "idle" && (
          <Dropzone
            onChooseFile={handleChooseFile}
            acceptedFileTypes={[...EXCEL_MIME_TYPES]}
          />
        )}
        {isValidating && (
          <ValidationProgress
            fileName={file.name}
            numRows={currentTask.progress?.total}
            percentageComplete={percentageValidated}
            secondsLeft={timeLeft}
            status={status}
          />
        )}
        {status === "success" && (
          <Banner
            className="mt-2.5 bg-green-light"
            title="Validation Successful"
            icon={<CheckmarkCircleIcon className="text-green" />}
          >
            <div className="mb-4">
              <BannerItem
                title="Total Entries"
                value={formatNumber(currentTask.progress?.total)}
              />
            </div>
            <div className="flex">
              <BannerItem
                className="mr-12"
                title="Success"
                value={formatNumber(currentTask.progress?.total)}
              />
              <BannerItem title="Failed" value="0" />
            </div>
          </Banner>
        )}
        {status === "failed" && (
          <Banner
            className="mt-2.5 bg-orange-light"
            title="Validation Failed"
            icon={<WarningIcon className="text-orange-lighter" />}
          >
            <BannerItem className="mb-4" title="Rows" value={errorText} />
            <BannerItem
              title="Insights"
              value={result.error ?? result.errors.summary}
            />
          </Banner>
        )}
        {doneValidating && (
          <React.Fragment>
            <div className="flex mt-8">
              <Button className="mr-5" outline onClick={reset}>
                {status === "failed" ? "Choose another file" : "Cancel"}
              </Button>
              {status !== "failed" && (
                <Button
                  className="w-48"
                  onClick={continueToNextStep}
                  variant={ButtonVariants.Green}
                >
                  Continue
                </Button>
              )}
            </div>
            {status !== "failed" && (
              <p className="mt-6 text-subtext text-xs leading-3 text-subtext">
                By continuing you accept our{" "}
                <span className="text-orange cursor-pointer">Terms of Use</span>
              </p>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
