import React from "react";
import Notification from "rc-notification";
import "./notification.css";
import { v4 as uuid } from "uuid";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { Spinner } from "../Spinner/Spinner";

let notificationInstance = null;

function getNotificationInstance() {
  return new Promise((resolve) => {
    if (notificationInstance) {
      resolve(notificationInstance);
      return;
    }
    Notification.newInstance(
      {
        prefixCls: "osip-notif",
        style: {
          top: "auto",
          right: 0,
          bottom: 24,
        },
        closeIcon: <CloseIcon className="text-black w-5" />,
        transitionName: "notif-trans",
        maxCount: 5,
      },
      (n) => {
        if (notificationInstance) {
          resolve(notificationInstance);
          return;
        }
        notificationInstance = n;
        resolve(notificationInstance);
      }
    );
  });
}

function renderNotification({
  duration = 2,
  closable = true,
  iconNode,
  title,
  description,
  onClose,
  style,
  className,
  key,
}) {
  return {
    content: (
      <div className="flex items-center">
        <div className="w-8 h-8 mr-4 flex items-center justify-center">
          {iconNode}
        </div>
        <div className="flex flex-col">
          {!!title && (
            <h5 className="text-lg text-heading font-bold">{title}</h5>
          )}
          {!!description && (
            <p className="text-sm text-subtext">{description}</p>
          )}
        </div>
      </div>
    ),
    key,
    onClose,
    duration: closable ? duration : 0,
    closable,
    style: style || {},
    className,
  };
}

const api = {
  destroy() {
    if (notificationInstance) {
      notificationInstance.destroy();
      notificationInstance = null;
    }
  },
  async notify({
    title,
    description,
    icon,
    closable = true,
    duration = 3,
    onClose,
  }) {
    const instance = await getNotificationInstance();
    const key = uuid();
    const dismiss = () => {
      instance.removeNotice(key);
      if (typeof onClose === "function") onClose(key);
    };
    instance.notice(
      renderNotification({
        key,
        iconNode: icon,
        duration,
        closable,
        title,
        description,
        onClose: typeof onClose === "function" ? () => onClose(key) : null,
      })
    );
    return [dismiss, key];
  },
  async loading({ title, description }) {
    return api.notify({
      title,
      description,
      icon: <Spinner className="w-6 h-6 border-t-orange" />,
      closable: false,
    });
  },
  async success({ title, description, onClose }) {
    return api.notify({
      title,
      description,
      onClose,
      icon: <CheckIcon className="w-6 h-6 text-orange" />,
    });
  },
  async failure({ title, description, onClose }) {
    return api.notify({
      title,
      description,
      onClose,
      icon: <CloseIcon className="w-6 h-6 text-orange" />,
    });
  },
};

// this line helps intellisense import this file as notification
// and not api
const notification = api;

export default notification;
