import axios from "axios";

async function login({ email, password }) {
  try {
    const response = await axios.post("/users/login", {
      email,
      password,
    });
    const { success, message, data } = response.data;

    if (success) {
      const { token, ...user } = data;
      return { success: true, token, user };
    } else {
      return {
        success: false,
        message,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message ?? error.message,
    };
  }
}

export const authApi = {
  login,
};
