import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import { rootReducer } from "./rootReducer";
import localForage from "localforage";

const middleware = [...getDefaultMiddleware()];

if (process.env.NODE_ENV !== "production") {
  const { createLogger } = require("redux-logger");
  middleware.push(createLogger());
}

const persistConfig = {
  key: "root",
  whitelist: ["auth"],
  storage: localForage,
};

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware,
});

export const persistor = persistStore(store);
