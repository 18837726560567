export const Roles = Object.freeze({
  Admin: "admin",
  Initiator: "initiator",
  Authorizer: "authorizer",
});

export const Permissions = Object.freeze({
  CreateTransaction: "create:transaction",
  // TODO: Add other permissions
});

const checks = {
  [Permissions.CreateTransaction]: {
    roles: [Roles.Initiator],
  },
  // TODO: Add other checks
};

export function isPermitted(user, permissions, data = undefined) {
  if (!user) return false;

  const { role } = user;

  if (!permissions) return true;
  const permissionArray = Array.isArray(permissions)
    ? permissions
    : [permissions];
  if (permissionArray.length === 0) return true;

  for (const permission of permissionArray) {
    const perm = checks[permission];

    if (!perm) {
      throw new Error(`Encountered unknown permission: ${permission}`);
    }

    if (perm.roles) {
      if (!perm.roles.includes(role)) {
        return false;
      }
    }

    let permitted = perm.check?.(user, data) ?? true;

    if (!permitted) return false;
  }

  return true;
}
