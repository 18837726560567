import Axios from "axios";

async function createTransaction(transactionDetails, progressCallback) {
  try {
    await Axios.post("/transactions/bulk-transfer", transactionDetails, {
      onUploadProgress: progressCallback,
    });
    return { success: true };
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message ?? error.message,
    };
  }
}

export const createTransactionApi = {
  createTransaction,
};
