import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { formatCurrency } from "../../../../utils/functions";
import moment from "moment";
import { Formik } from "formik";
import { ValidatedInput } from "../../../../components/Form/Input";
import { Button, ButtonVariants } from "../../../../components/Form/Button";
import { useTransactionContext } from "./TransactionContext";
import { useHistory } from "react-router-dom";
import { appRoutes } from "../../../routes";
import * as Yup from "yup";
import { authSelectors } from "../../../auth/authSlice";
import { useSelector } from "react-redux";
import { createTransactionApi } from "./createApi";
import notification from "../../../../components/Notification/notification";
import { useProgressDuration } from "../../../../hooks/useProgressDuration";
import { UploadProgress } from "../../../../components/Progress/UploadProgress";

const Row = ({ children, collapse = false }) => (
  <div className={classNames("flex justify-between", !collapse && "mb-2.5")}>
    {children}
  </div>
);

const Left = ({ children }) => (
  <p className="text-base text-subtext leading-5">{children}</p>
);

const Right = ({ children }) => (
  <p className="text-lg text-heading font-medium leading-5 text-right">
    {children}
  </p>
);

const _formatDate = (date) => moment(date).format("MMMM D, YYYY");

const reviewSchema = Yup.object({
  narration: Yup.string().required(
    "Please provide information about this transaction"
  ),
});

export function Reivew() {
  const {
    recipients,
    amount,
    isStepDone,
    setNarration,
    nextStep,
    jumpToStep,
  } = useTransactionContext();
  const { push } = useHistory();
  const user = useSelector(authSelectors.user);
  const [status, setStatus] = useState("idle");
  const [progress, setProgress] = useState({
    total: null,
    done: null,
  });
  const { timeLeft, reset } = useProgressDuration(progress);

  useEffect(() => {
    if (isStepDone("review")) {
      push(appRoutes.Transaction.Create.Pay);
    }
    if (!isStepDone("amount")) {
      push(appRoutes.Transaction.Create.Amount);
    }
  }, [isStepDone, push]);

  async function handleSubmit({ narration }) {
    setNarration(narration);
    setStatus("submitting");
    reset();
    const { success, message } = await createTransactionApi.createTransaction(
      {
        narration,
        beneficiaries: recipients,
        amount,
      },
      (progressEvent) => {
        setProgress({
          total: progressEvent.total,
          done: progressEvent.loaded,
        });
      }
    );

    if (!success) {
      notification.failure({
        title: "Failed to initialize transaction",
        description: message,
      });
      setStatus("failure");
    } else {
      setStatus("success");
      nextStep(() => push(appRoutes.Transaction.Create.Pay));
    }
  }

  function goBack() {
    jumpToStep("amount", () => push(appRoutes.Transaction.Create.Amount));
  }

  const percentageComplete = Math.ceil((progress.done / progress.total) * 100);

  return (
    <Formik
      initialValues={{ narration: "" }}
      validationSchema={reviewSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <form
          className="flex flex-col max-w-full"
          style={{ width: "26.5rem" }}
          onSubmit={handleSubmit}
        >
          <h5 className="text-disabled text-base leading-5 mb-6 font-medium">
            Transaction Details
          </h5>
          <Row>
            <Left>Each recipient gets</Left>
            <Right>{formatCurrency(amount, true, false)}</Right>
          </Row>
          <Row>
            <Left>Total Beneficiaries</Left>
            <Right>{recipients.length}</Right>
          </Row>
          <Row collapse>
            <Left>Total Amount</Left>
            <Right>
              {formatCurrency(recipients.length * amount, true, false)}
            </Right>
          </Row>
          <div className="border-t my-5" />
          <Row>
            <Left>Initiator</Left>
            <Right>{user?.name}</Right>
          </Row>
          <Row collapse>
            <Left>Date</Left>
            <Right>{_formatDate(new Date())}</Right>
          </Row>
          <ValidatedInput
            className="mt-5"
            name="narration"
            label="Reference"
            placeholder="Type a reference"
          />
          <div className="flex mt-10">
            {status === "idle" && (
              <React.Fragment>
                <Button onClick={goBack} className="mr-4" outline>
                  Go Back
                </Button>
                <Button type="submit" variant={ButtonVariants.Green}>
                  Confirm &amp; Continue
                </Button>
              </React.Fragment>
            )}
            {status === "submitting" && (
              <UploadProgress
                fileName={"Creating transaction..."}
                fileSize={progress.total ?? 0}
                secondsLeft={timeLeft}
                percentageComplete={percentageComplete}
                status={status}
              />
            )}
            {status === "failure" && (
              <Button type="submit" variant={ButtonVariants.Green}>
                Retry
              </Button>
            )}
          </div>
        </form>
      )}
    </Formik>
  );
}
